import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import useStyles from './styles.js';

// Helper function to normalize name (lowercase and remove diacritics)
const normalizeString = (str) => {
  return str
    .toLowerCase()
    .normalize('NFD') // Decomposes accented characters into base character + diacritical mark
    .replace(/[\u0300-\u036f]/g, ''); // Removes the diacritical marks
};

const fetchArtistas = async () => {
  const response = await axios.get('https://oyster-app-jiunm.ondigitalocean.app/airtable-data', {
    params: {
      table_name: 'Speakers',
    },
  });
  return response.data;
};

const SpeakerDetail = () => {
  const classes = useStyles();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const location = useLocation();
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const { name } = useParams();

  const formattedName = normalizeString(name.replace(/-/g, ' '));

  const { data, error, isLoading } = useQuery({
    queryKey: ['speakers'],
    queryFn: fetchArtistas,
  });

  if (isLoading) return <p>Loading artist details...</p>;
  if (error) return <p>Error loading artist details: {error.message}</p>;

  const artist = data.find((artista) => normalizeString(artista.fields.Name) === formattedName);

  return (
    <div className={classes.container}>
      {!artist ? (
        <p>No artist found with the name: {formattedName}</p>
      ) : (
        <>
          <div className={classes.row}>
            <div className={classes.colImage}>
              <div className={classes.ratio}>
                {artist.fields.Images && artist.fields.Images.length > 0 && (
                  <img
                    className={classes.image}
                    src={artist.fields.Images[1]?.thumbnails.full.url || artist.fields.Images[0].thumbnails.full.url}
                    alt={artist.fields.Name}
                  />
                )}
              </div>
            </div>
            {/* <div className={classes.row}>
                <a href={artist.fields.Facebook} target="_blank" rel="noopener noreferrer">
                  <img className={classes.socials} src={Facebook} alt="facebook" />
                </a>
                <a href={artist.fields.Youtube} target="_blank" rel="noopener noreferrer">
                  <img className={classes.socials} src={Youtube} alt="Youtube" />
                </a>
                <a href={artist.fields.Instagram} target="_blank" rel="noopener noreferrer">
                  <img className={classes.socials} src={Instagram} alt="Instagram" />
                </a>
                <a href={artist.fields.Website} target="_blank" rel="noopener noreferrer">
                  <img className={classes.socials} src={Website} alt="Website" />
                </a>
              </div> */}

            <div className={classes.column}>
              <h1 className={classes.title}>{artist.fields.Name}</h1>
              <div className={classes.description}>
                <p>{lang === 'pt' ? artist.fields['Bio PT'] : artist.fields.Bio}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SpeakerDetail;
