import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Hook to get current location
import useStyles from '../styles';

import Hero from 'components/hero';
import Footer from 'components/common/footer';

import Section1 from 'components/homepage/section1';
import Section3 from 'components/homepage/section3';

import Partners from 'components/partners';
import ArtistasList from 'components/homepage/highlight-artists.js';
import SpeakersList from 'components/homepage/highlight-speakers';

import Header from 'components/common/header';

const HomePage = () => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  return (
    <div className={classes.App}>
      <Header home lang={lang} />
      <Hero lang={lang} />

      <main className={classes.main}>
        <Section1 language={lang} />
        <Section3 langueage={lang} />
        <ArtistasList lang={lang} /> {/* Render Portuguese or English based on lang */}
        <SpeakersList lang={lang} />
      </main>

      <Partners lang={lang} />
      <Footer lang={lang} />
    </div>
  );
};

export default HomePage;
