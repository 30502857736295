import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import cx from 'classnames';
import useStyles from './styles';
import pt from 'assets/pt.svg';
import en from 'assets/en.svg';
import investidor from 'assets/partners/braga25/braga25.png';
import investidorRed from 'assets/partners/braga25/braga25-red.png';
import investidorPT from 'assets/partners/braga25/logo_braga25_CMYK_branco.png';
import investidorPTRed from 'assets/partners/braga25/braga25-red-pt.png';
import SquareSGreen from 'assets/SquareSGreen.svg';
import SquareSRed from 'assets/SquareSRed.svg';
import TheSpacer from '../spacer/thespacer';
import PropTypes from 'prop-types';
import MobileMenu from '../mobile-menu';

let languageMap = {
  '/': '/pt',
  '/pt': '/',
  '/artists': '/pt/artistas',
  '/pt/artistas': '/artists',
  '/artist': '/pt/artista',
  '/pt/artista': '/artist',
  '/conference': '/pt/conferencia',
  '/pt/conferencia': '/conference',
  '/speaker': '/pt/orador',
  '/pt/orador': '/speaker',
  '/terms-and-conditions': '/pt/terms-and-conditions',
  '/pt/terms-and-conditions': '/terms-and-conditions',
  '/tickets': '/pt/bilhetes',
  '/pt/bilhetes': '/tickets',
  '/faqs': '/pt/faqs',
  '/pt/faqs': '/faqs',
  '/news': '/pt/noticias',
  '/pt/noticias': '/news',
};

const getLanguageRoute = (pathname, lang) => {
  let baseRoute;
  let remainingPath;

  if (lang === 'en') {
    baseRoute = `/${pathname.split('/')[1]}`;
    remainingPath = pathname.split('/').slice(2).join('/');
  } else {
    baseRoute = `/${pathname.split('/')[1]}/${pathname.split('/')[2]}`;
    remainingPath = pathname.split('/').slice(3).join('/');
  }

  if (languageMap[pathname]) {
    return languageMap[pathname];
  }

  return `${baseRoute}${remainingPath ? `/${remainingPath}` : ''}`;
};

const Header = ({ home, tickets, artists, conference, news, about, faqs, lang, data }) => {
  const language = lang;
  const classes = useStyles({ home, tickets, artists, conference, news, about, faqs });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [isScrolled, setIsScrolled] = useState(false);
  const [switchLanguageLink, setSwitchLanguageLink] = useState(""); // State for switchLanguageLink

  const location = useLocation();

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1024);
    setIsDesktop(window.innerWidth > 1024);
  };

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  useEffect(() => {
    const mapSlugs = () => {

      if (data && data.length > 0) {
        const mappedSlugs = data.map((data) => ({
          slug: data.fields.slug,
          slugPT: data.fields.slugPT,
        }));

        mappedSlugs.forEach(({ slug, slugPT }) => {
          languageMap[`/news/${slug}`] = `/pt/noticias/${slugPT}`;
          languageMap[`/pt/noticias/${slugPT}`] = `/news/${slug}`;
        });

        setSwitchLanguageLink(getLanguageRoute(location.pathname, lang));
      } else {
        setSwitchLanguageLink(getLanguageRoute(location.pathname, lang));
      }
    };

    mapSlugs();
  }, [data, location.pathname, lang]);


  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <header
      className={cx(
        classes.header,
        { home, tickets, artists, conference, news, about, faqs },
        { scrolled: isScrolled },
        { scrolledMobile: isScrolled && isMobile }
      )}
    >
      {language === 'en' ? (
        <Link className={cx(classes.square, { scrolled: isScrolled })} to="/">
          <img style={{ width: '100%' }} src={artists ? SquareSRed : SquareSGreen} alt="SquareLogo" />
        </Link>
      ) : (
        <Link className={cx(classes.square, { scrolled: isScrolled })} to="/pt">
          <img style={{ width: '100%' }} src={artists ? SquareSRed : SquareSGreen} alt="SquareLogo" />
        </Link>
      )}

      <div className={classes.headerContent}>
        {(() => {
          let imgSrc;
          let linkTo;

          if (lang === 'en') {
            imgSrc = artists ? investidorRed : investidor;
            linkTo = 'https://braga25.pt/en/';
          } else if (lang === 'pt') {
            imgSrc = artists ? investidorPTRed : investidorPT;
            linkTo = 'https://braga25.pt';
          }

          return imgSrc ? (
            <a
              className={cx(classes.investidor, { scrolled: isScrolled })}
              href={linkTo}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={imgSrc} alt="braga25" />
            </a>
          ) : null;
        })()}

        {isDesktop && (
          <>
            <div
              className={cx(classes.navigation, { scrolled: isScrolled }, { scrolledArtists: isScrolled && artists })}
            >
              <div className={classes.navItem}>
                <Link to={lang === 'en' ? '/' : '/pt'}>Home</Link>
                {home && <div className={classes.current} />}
              </div>
              <TheSpacer size={24} horizontal />
              <div className={classes.navItem}>
                <Link to={lang === 'en' ? '/tickets' : '/pt/bilhetes'}>
                  {language === 'en' ? 'Tickets' : 'Bilhetes'}
                </Link>
                {tickets && <div className={classes.current} />}
              </div>
              <TheSpacer size={24} horizontal />
              <div className={cx(classes.navItem, { artists })}>
                <Link to={lang === 'en' ? '/artists' : '/pt/artistas'}>{lang === 'en' ? 'Artists' : 'Artistas'}</Link>
                {artists && <div className={cx(classes.current, 'artists')} />}
              </div>
              <TheSpacer size={24} horizontal />
              <div className={cx(classes.navItem, { conference })}>
                <Link to={lang === 'en' ? '/conference' : '/pt/conferencia'}>
                  {language === 'en' ? 'Conference' : 'Conferência'}
                </Link>
                {conference && <div className={classes.current} />}
              </div>
              <TheSpacer size={24} horizontal />
              <div className={classes.navItem}>
                <Link to={lang === 'en' ? '/news' : '/pt/noticias'}>{language === 'en' ? 'News' : 'Notícias'}</Link>
                {news && <div className={classes.current} />}
              </div>
              <TheSpacer size={24} horizontal />
              <div className={classes.navItem}>
                <Link to={lang === 'en' ? '/faqs' : '/pt/faqs'}>FAQs</Link>
                {faqs && <div className={classes.current} />}
              </div>
            </div>

            {language === 'en' ? (
              <Link className={classes.heroLanguageChanger} to={switchLanguageLink}>
                <img src={pt} alt="pt" />
              </Link>
            ) : (
              <Link className={classes.heroLanguageChanger} to={switchLanguageLink}>
                <img src={en} alt="en" />
              </Link>
            )}
          </>
        )}
        {isMobile && (
          <MobileMenu
            home={home}
            tickets={tickets}
            artists={artists}
            conference={conference}
            news={news}
            about={about}
            faqs={faqs}
            lang={language}
            link={switchLanguageLink}
          />
        )}
      </div>
    </header>
  );
};

Header.propTypes = {
  home: PropTypes.bool,
  tickets: PropTypes.bool,
  artists: PropTypes.bool,
  conference: PropTypes.bool,
  news: PropTypes.bool,
  about: PropTypes.bool,
  faqs: PropTypes.bool,
  lang: PropTypes.string,
};

export default Header;
