import { createUseStyles } from 'react-jss';

const useStylesNews = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '240px 2rem 120px',
    margin: '0 auto',
    color: 'white',
    width: '76%',
    maxWidth: '960px',
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: "100%"
  },

  column: {
    width: '61.8%',
    display: 'flex',
    flexDirection: 'column',
  },

  colImage: {
    width: '38.2%',
  },
  ratio: {
    paddingBottom: '100%',
    position: 'relative',
    border: '2px solid var(--green)',
  },
  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },

  date: {
    fontSize: '24px',
    color: 'var(--green)',
    fontWeight: '600',
    marginBottom: '8px',
  },

  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '1.2px',
  },

  description: {
    fontSize: '18px',
    lineHeight: '1.6em',

    '& p': {
      margin: "revert"
    },
  },

  '@media (max-width: 1024px)': {
    container: {
      width: "100%",
      padding: '150px 1.5rem 60px',
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
    },
    column: {
      width: '100%',
      gap: "1.5rem"
    },
    colImage: {
      width: '100%',
      marginBottom: '0.5rem',
    }
  },
});

export default useStylesNews;
