import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  hero: {
    maxHeight: '100vh',
    overflow: 'hidden',
    backgroundColor: 'var(--main-background-color)',
  },
  heroContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  videoDesktop: {
    width: '100%',
    padding: '56.25% 0 0 0',
    position: 'relative',
    height: '100vh',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  videoMobile: {
    width: '100%',
    padding: '177.78% 0 0 0',
    position: 'relative'
  },
  heroTop: {
    padding: '2rem 2rem 0 2rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  investidor: {
    width: '200px',
  },
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '750px',
    width: '80%',
    margin: '0 auto',
    height: '100%',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#111111',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  heroTextWrap: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '3rem',
    gap: '1rem',
  },
  heroText: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: 'calc(1rem + 1vw)',
    textAlign: 'left',
    color: 'var(--main-color)',
  },
  '@media (max-width: 1024px)': {
    heroTop: {
      padding: '1rem',
    },
  },
});

export default useStyles;
