import React, { useState, useEffect } from 'react';
import useStyles from './styles';

import logo from 'assets/logo.svg';

const Hero = (props) => {

  const classes = useStyles();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className={classes.hero}>
        {isDesktop && (
          <div className={classes.videoDesktop}>
            <iframe
              className={classes.video}
              src="https://player.vimeo.com/video/931483864?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&background=1&title=0&byline=0&portrait=0"
              frameBorder={0}
              title="Square-exp2"
            />
            <div className={classes.overlay} />
            <div className={classes.heroContent}>
              <img src={logo} alt="logo" className={classes.logo} />
              <div className={classes.heroTextWrap}>
                <p className={classes.heroText}>29.01.2025 — 1.02.2025</p>
              </div>
            </div>
          </div>
        )}
        {isMobile && (
          <div className={classes.videoMobile}>
            <iframe
              src="https://player.vimeo.com/video/931483894?badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1&loop=1&background=1&title=0&byline=0&portrait=0"
              frameBorder={0}
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
              className={classes.video}
              title="Vídeo Loop Square_Vertical"
            />
            <div className={classes.overlay} />
            <div className={classes.heroContent}>

              <img src={logo} alt="logo" className={classes.logo} />
              <div className={classes.heroTextWrap}>
                <p className={classes.heroText}>29.01.2025 — 1.02.2025</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Hero;
