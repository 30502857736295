import React, { useEffect } from 'react';
import './mailchimp.css'; // Import the CSS file

function MailchimpForm({ lang = 'en' }) {
  const submit = lang === "pt" ? "Subscrever" : "Subscribe";

  useEffect(() => {
    // Check if the script is already loaded
    const isScriptLoaded = (src) => {
      return Array.from(document.scripts).some(script => script.src.includes(src));
    };

    if (!isScriptLoaded('jquery-3.6.0.min.js')) {
      const jqueryScript = document.createElement('script');
      jqueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
      jqueryScript.onload = () => {
        if (!isScriptLoaded('mc-validate.js')) {
          const mcScript = document.createElement('script');
          mcScript.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
          document.body.appendChild(mcScript);
        }
      };
      document.body.appendChild(jqueryScript);
    }

    // Append the inline script if it doesn't already exist
    if (!document.getElementById('mailchimp-inline-script')) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'mailchimp-inline-script';
      script.innerHTML = `
        window.fnames = new Array();
        window.ftypes = new Array();
        fnames[0]='EMAIL';
        ftypes[0]='email';
        fnames[1]='FNAME';
        ftypes[1]='text';
        fnames[2]='LNAME';
        ftypes[2]='text';
        fnames[3]='ADDRESS';
        ftypes[3]='address';
        fnames[4]='PHONE';
        ftypes[4]='phone';
        fnames[5]='BIRTHDAY';
        ftypes[5]='birthday';
      `;
      document.body.appendChild(script);
    }

    // Clean-up function
    return () => {
      // We won’t remove the scripts here because we want to prevent multiple loads,
      // and removing them would mean they'd need to be added again.
    };
  }, []);

  return (
    <div id="mc_embed_shell">
      <div id="mc_embed_signup">
        <form
          action="https://squarefestival.us18.list-manage.com/subscribe/post?u=63c990832b730e6c533b53c9d&amp;id=4ddb580f37&amp;f_id=00f9b9e7f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
        >
          <div id="mc_embed_signup_scroll">
            <h2>Newsletter</h2>

            <div className="indicates-required">
              <span className="asterisk">*</span> {lang === "en" ? "indicates required" : "campo obrigatório"}
            </div>

            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">Email <span className="asterisk">*</span></label>
              <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
            </div>

            <div className="mc-field-group">
              <label htmlFor="mce-FNAME">
                {lang === "en" ? "First Name" : "Primeiro nome"} <span className="asterisk">*</span>
              </label>
              <input type="text" name="FNAME" className="required text" id="mce-FNAME" required />
            </div>

            <div id="mce-responses" className="clear foot">
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>

            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <input type="text" name="b_63c990832b730e6c533b53c9d_4ddb580f37" tabIndex="-1" defaultValue="" />
            </div>

            <div className="optionalParent">
              <div className="clear foot">
                <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value={submit} />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default MailchimpForm;
