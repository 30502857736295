import { React, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { marked } from 'marked';

import useStyles from './styles';
import TheSpacer from 'components/common/spacer/thespacer';

const NewsDetail = ({ data }) => {
  const classes = useStyles();
  const { name } = useParams();
  const location = useLocation();
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const news = data
    ? data.find((item) => lang === 'pt'
      ? item.fields.slugPT === name
      : item.fields.slug === name)
    : null;

  return (
    <div className={classes.container}>
      {!news ? (
        <p>No news found with the name: {name}</p>
      ) : (
        <>
          <div className={classes.row}>
            <div className={classes.colImage}>
              <div className={classes.ratio}>
                <img
                  className={classes.image}
                  src={
                    (news.fields.Images && news.fields.Images[1] && news.fields.Images[1].thumbnails?.full?.url) ||
                    (news.fields.Images && news.fields.Images[0] && news.fields.Images[0].thumbnails?.full?.url)
                  }
                  alt={news.fields.Name}
                />
              </div>
            </div>
            <TheSpacer size={32} horizontal />
            <div className={classes.column}>
              <p className={classes.date}>{news.fields.Date}</p>
              <h1 className={classes.title}>{lang === 'pt' ? news.fields['Name PT'] : news.fields.Name}</h1>
            </div>
          </div>
          <TheSpacer size={32} vertical />
          <div
            className={classes.description}
            dangerouslySetInnerHTML={{
              __html: lang === 'pt' ? marked(news.fields['Description PT'] || '') : marked(news.fields.Description || ''),
            }}
          />
        </>
      )}
    </div>
  );
};

export default NewsDetail;
