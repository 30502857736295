import React, { Fragment } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Router from './Router.js';


const queryClient = new QueryClient();

const App = () => {
  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </Fragment>
  );
};

export default App;
