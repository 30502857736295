import axios from 'axios';

// Airtable base ID and API key
const baseId = process.env.REACT_APP_AIRTABLE_BASE_ID;
const apiKey = process.env.REACT_APP_AIRTABLE_API_KEY;

// Create an Axios instance with the Airtable base URL
const airtableInstance = axios.create({
  baseURL: `https://api.airtable.com/v0/${baseId}`,
  headers: {
    Authorization: `Bearer ${apiKey}`
  }
});

// Function to fetch data from a specific table
const fetchData = async (tableName) => {
  try {
    const response = await airtableInstance.get(`/${tableName}`);
    return response.data.records;
  } catch (error) {
    console.error('Error fetching data from Airtable:', error);
    return [];
  }
};

export default fetchData;
