import React, { useEffect } from 'react';
import useStyles from './styles';
import { useLocation } from 'react-router-dom';

import Header from 'components/common/header';
import SpeakerDetail from 'components/speaker-detail';
import Footer from 'components/common/footer';


const SpeakerDetailPage = () => {

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = useStyles();
  const lang = pathname.startsWith('/pt') ? 'pt' : 'en';

  return (
    <>
      <Header conference lang={lang} />
      <main className={classes.main}>
        <SpeakerDetail lang={lang} />
      </main>

      <Footer lang={'en'} />
    </>
  );
};

export default SpeakerDetailPage;
