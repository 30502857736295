import React from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { marked } from 'marked'; // Fix the import
import useStyles from '../../pages/styles';

// Fetch data function
const fetchSection = async () => {
  const response = await axios.get('https://oyster-app-jiunm.ondigitalocean.app/airtable-data', {
    params: {
      table_name: 'Homepage',
    },
  });
  return response.data;
};

const Section3 = () => {
  const classes = useStyles();
  const location = useLocation();

  // Determine language based on the URL path
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  // Use useQuery to fetch and cache data
  const { data: records, isLoading, isError } = useQuery({
    queryKey: ['sectionData', lang],
    queryFn: fetchSection,
  });

  // Filter and extract necessary fields after data is fetched
  const filteredRecords = records?.filter((record) => record.fields.Name === 'Square 3') || [];

  let description = '';
  let image1 = '';
  let image1Alt = '';
  let image2 = '';
  let image2Alt = '';

  if (filteredRecords.length > 0) {
    // Use 'Description PT' if lang is 'pt', otherwise use 'Description'
    description = lang === 'pt'
      ? filteredRecords[0].fields['Description PT']
      : filteredRecords[0].fields.Description;

    const assets = filteredRecords[0].fields.Assets || [];

    image1 = assets.length > 0 ? assets[0].thumbnails.large.url : '';
    image1Alt = assets.length > 0 ? assets[0].filename : '';

    image2 = assets.length > 1 ? assets[1].thumbnails.large.url : '';
    image2Alt = assets.length > 1 ? assets[1].filename : '';
  }

  // Convert Markdown to HTML using marked
  const descriptionHtml = marked(description);

  return (
    <section className={`${classes.card} ${classes.bgGreen}`}>
      {isLoading && <p className={classes.loadingText}>Loading...</p>}
      {isError && <p className={classes.errorText}>Error loading data</p>}
      {!isLoading && !isError && (
        <>
          <div className={classes.ratio}>
            {/* Render the description as HTML */}
            <p
              className={classes.cardText}
              dangerouslySetInnerHTML={{ __html: descriptionHtml }}
            />
          </div>
          <div className={classes.image4}>
            <img src={image1} alt={image1Alt} className={classes.image} loading="lazy" />
          </div>
          <div className={classes.image5}>
            <img src={image2} alt={image2Alt} className={classes.image} loading="lazy" />
          </div>
        </>
      )}
    </section>
  );
};

export default Section3;
