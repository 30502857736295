import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import useStyles from './styles';
import Header from 'components/common/header';
import NewsDetail from 'components/news-detail';
import Footer from 'components/common/footer';


const fetchNews = async () => {
  const response = await axios.get('https://oyster-app-jiunm.ondigitalocean.app/airtable-data', {
    params: { table_name: 'News' },
  });

  return response.data.filter((item) => item.fields.Status !== 'In progress');

};

const NewsDetailPage = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const lang = pathname.startsWith('/pt') ? 'pt' : 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);


  const { data, error, isLoading } = useQuery({
    queryKey: ['news'],
    queryFn: fetchNews,
  });


  return (
    <>
      <Header news lang={lang} data={data} />
      <main className={classes.main}>
        <NewsDetail lang={lang} data={data} error={error} isLoading={isLoading} />
      </main>
      <Footer lang={lang} />
    </>
  );
};

export default NewsDetailPage;
