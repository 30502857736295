import React from 'react';
import useStyles from './styles.js';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { Link, useLocation } from 'react-router-dom';

const fetchArtistas = async () => {
  const response = await axios.get('https://oyster-app-jiunm.ondigitalocean.app/airtable-data', {
    params: {
      table_name: 'Speakers',
    },
  });
  return response.data;
};

const SpeakerList = (props) => {
  const classes = useStyles();

  const location = useLocation();
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const { data, error, isLoading } = useQuery({
    queryKey: ['speakers'],
    queryFn: fetchArtistas,
  });

  function capitalizeEveryWord(str) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  function formatNameForUrl(name) {
    return name.toLowerCase().replace(/\s+/g, '-');
  }

  // Sort the speakers alphabetically by name
  const sortedData = data ? [...data].sort((a, b) => a.fields.Name.localeCompare(b.fields.Name)) : [];

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div className={classes.container}>
      <div className={classes.speakersList}>
        {sortedData.map((speaker) => (
          <Link
            to={
              lang === 'pt'
                ? `/pt/orador/${formatNameForUrl(speaker.fields.Name)}`
                : `/speaker/${formatNameForUrl(speaker.fields.Name)}`
            } // Using speaker name in the URL
            className={classes.speakers}
            key={speaker.id}
          >
            <div className="col justify-center align-center" style={{ width: '100%' }}>
              <div className={classes.imageContainer}>
                {speaker.fields.Images && speaker.fields.Images.length > 0 && (
                  <img
                    src={speaker.fields.Images[0].thumbnails.large.url}
                    className={classes.image}
                    alt={speaker.fields.Name}
                    loading="lazy"
                  />
                )}
              </div>

              <p className={classes.speakersTitle}>
                {capitalizeEveryWord(speaker.fields.Name)} <span>{speaker.fields.Country}</span>
              </p>
              <p className={classes.speakersAbout}>{speaker.fields[lang === 'pt' ? 'Role-PT' : 'Role']}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default SpeakerList;
