import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import useStyles from '../styles';

import Header from 'components/common/header';
import Footer from 'components/common/footer';
import NewsList from 'components/news-list';

const fetchNews = async () => {
  const response = await axios.get('https://oyster-app-jiunm.ondigitalocean.app/airtable-data', {
    params: { table_name: 'News' },
  });

  return response.data.filter((item) => item.fields.Status !== 'In progress');
};

const News = () => {
  const globalClasses = useStyles();
  const { pathname } = useLocation();

  const lang = pathname.startsWith('/pt') ? 'pt' : 'en';

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { data, error, isLoading } = useQuery({
    queryKey: ['news'],
    queryFn: fetchNews,
  });

  return (
    <div className={globalClasses.App}>
      <Header news lang={lang} error={error} isLoading={isLoading} />
      <main className={globalClasses.main}>
        <NewsList lang={lang} data={data} error={error} isLoading={isLoading} />
      </main>
      <Footer lang={lang} />
    </div>
  );
};

export default News;
