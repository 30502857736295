import React, { useEffect } from 'react';
import useStyles from '../styles';

import Hero from 'components/common/header';
import Footer from 'components/common/footer';
import { useLocation } from 'react-router-dom';

const HomePage = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = useStyles();

  return (
    <div className={classes.App}>
      <Hero lang={'pt'} isSingle />

      <main className={`${classes.main} isSingle`}>
        <section className={`${classes.card} ${classes.bgGreen} isSingle`}>
          <div className={classes.ratio}>
            <div className={classes.cardText}>
              <p dir="ltr">
                O Open Call do Square - Mapping the Atlantic 2025 é aberto a artistas residentes em qualquer país da
                Europa, de África e das Américas.
                <br />
                <br />
              </p>
              <p dir="ltr">
                Em caso de seleção, o SQUARE 2025 irá garantir:
                <br />
                <br />
              </p>
              <ul>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Contribuição de 750€ por atuação (os pagamentos requerem a emissão de uma fatura);
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Jantar para a noite da apresentação, para cada artista em palco + profissional de som +
                    representante artista (manager, agente, etc);
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Equipamentos de Som e luzes standard + backline partilhado (amplificador de baixo e guitarra,
                    bateria padrão, equipamento de DJ);
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Assistência técnica antes, durante e após o espetáculo (som, luz e palco);
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Promoção de artistas: o Square irá comunicar sobre artistas/bandas através das suas ferramentas de
                    comunicação;
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Contacto com jornalistas e meios de comunicação;
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Duas credenciais PRO para representantes de artistas/bandas, que proporcionam acesso total ao
                    programa da convenção e do festival;
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Ferramentas para a entidade que faz a representação de artistas/bandas entrar em contacto com
                    participantes do Square;
                  </p>
                </li>
              </ul>
              <p dir="ltr">
                Custos extra, deixados à entidade entidade que faz a representação de artistas/bandas, em caso de
                necessidade:
              </p>
              <ul>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    Pedidos técnicos específicos e backline adicional;
                  </p>
                </li>
                <li dir="ltr" aria-level={1}>
                  <p dir="ltr" role="presentation">
                    É acordado e compreendido que artistas/bandas terão que partilhar o palco e o backstage com
                    artistas/bandas também integrados no festival. Artistas que tocarão diferentes géneros musicais e
                    serão provenientes de diferentes países. No seguimento disto, também se concorda com a eventualidade
                    de partilha de backline.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default HomePage;
