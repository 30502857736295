import React, { useEffect } from 'react';
import useStyles from '../styles';
import { useLocation } from 'react-router-dom';
import Header from 'components/common/header';
import ArtistasList from 'components/artists-list';
import Footer from 'components/common/footer';

const Artists = () => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  return (
    <div className={classes.App}>
      <Header lang={lang} artists />
      <main className={`${classes.main} ${'artists'}`}>
        <ArtistasList lang={lang} />
      </main>
      <Footer lang={lang} />
    </div>
  );
};

export default Artists;
