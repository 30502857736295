import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({

  partnerSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    backgroundColor: 'white',
    flexDirection: 'column',
    padding: '4rem 2rem',
    minHeight: "100vh"
  },
  partnersMaxWidth: {
    width: "100%",
    maxWidth: "860px"
  },
  partnerList: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '3rem'
  },
  partner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '150px',
    '& img': {
      padding: "5px",
      height: '65px',
      objectFit: 'contain'
    },
    '& img.big': {
      height: "4.2cm",
      padding: 0
    },
  },
  partnersTitle: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '25px',
    fontWeight: '500',
    paddingBottom: '2rem'
  },
  partnerTitle: {
    fontFamily: 'Switzer',
    fontSize: '0.9rem',
    fontWeight: '700',
    padding: "3rem 0 1.5rem"
  },

  '@media (max-width: 1024px)': {
    partnerList: {
      gap: "2rem"
    },
    partner: {
      width: "calc(50% - 2rem)",
      justifyContent: "flex-start",
      '& img': {
        height: '50px',
      },
    },
    partnerSection: {
      padding: "4rem 1rem"
    }
  },



});


export default useStyles;
