import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  button: (props) => ({
    backgroundColor:
      props.background === 'green'
        ? 'var(--green)'
        : props.background === 'red'
        ? 'var(--red)'
        : props.background === 'brown'
        ? 'var(--brown)'
        : 'var(--main-color)',
    padding: props.padding || '1rem 1.5rem',
    fontFamily: 'Switzer',
    fontSize: props.fontSize || '24px', // Set font size from props or default to 22px
    fontWeight: 600,
    color:
      props.color === 'green'
        ? 'var(--green)'
        : props.color === 'red'
        ? 'var(--red)'
        : props.color === 'brown'
        ? 'var(--main-background-color)'
        : 'white',
    letterSpacing: '0.4px',
    transition: '250ms all',
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: 'var(--main-background-color)',
      color: 'var(--main-color)',
    },
  }),
  icon: {
    marginLeft: '8px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '20px',
      height: '20px',
      fill: (props) => props.color || 'currentColor',
    },
  },
});

export default useStyles;
