import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  title: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: '32px',
    fontWeight: 'heavy',
    marginBottom: '32px',
    alignSelf: 'center',
    color: 'var(--green)',
  },

  description: {
    fontSize: '16px',
    lineHeight: '1.8',
    letterSpacing: '0.4px',
  },
});

export default useStyles;
