import React from 'react';
import PropTypes from 'prop-types';

const TheSpacer = ({ vertical, size, marginAuto, text }) => {
  const resolvedSize = typeof size === 'number' ? size + 'px' : size; // Handle number or string

  return (
    <div
      style={{
        minWidth: !vertical && resolvedSize,
        maxWidth: !vertical && resolvedSize,
        minHeight: vertical && resolvedSize,
        maxHeight: vertical && resolvedSize,
        marginLeft: marginAuto && 'auto',
        marginRight: marginAuto && 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {text ? <p style={{ fontFamily: 'Lexend', fontSize: '16px', fontWeight: '400' }}>{text}</p> : null}
    </div>
  );
};

TheSpacer.propTypes = {
  vertical: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Allow string or number
  marginAuto: PropTypes.bool,
  text: PropTypes.string,
};

export default TheSpacer;
