import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router-dom';

import axios from 'axios';
import useStyles from './styles.js';

import Facebook from 'assets/icons/FacebookArtist.svg';
import Youtube from 'assets/icons/YoutubeArtist.svg';
import Instagram from 'assets/icons/InstagramArtist.svg';
import Website from 'assets/icons/WebsiteArtist.svg';

const fetchArtistas = async () => {
  const response = await axios.get('https://oyster-app-jiunm.ondigitalocean.app/airtable-data', {
    params: {
      table_name: 'Artistas',
    },
  });
  return response.data;
};

const ArtistDetail = () => {
  const classes = useStyles();

  const { name } = useParams();
  const formattedName = name.replace(/-/g, ' ').toUpperCase();

  const location = useLocation();
  const lang = location.pathname.startsWith('/pt') ? 'pt' : 'en';

  const { data, error, isLoading } = useQuery({
    queryKey: ['artistas'],
    queryFn: fetchArtistas,
  });

  // Finding the artist matching the formatted name
  const artist = data?.find((artista) => artista.fields.Name.toUpperCase() === formattedName);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
      setIsDesktop(window.innerWidth > 1024);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getEmbedUrl = (url) => {
    const youtubeMatch = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/watch\?v=|youtu.be\/)([a-zA-Z0-9_-]+)/);
    if (youtubeMatch && youtubeMatch[1]) {
      return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    }
    return url;
  };

  return (
    <div className={classes.container}>
      {isLoading && <p>Loading artist details...</p>}
      {error && <p>Error loading artist details: {error.message}</p>}

      {!isLoading && !error && (
        <>
          {!artist ? (
            <p>No artist found with the name: {formattedName}</p>
          ) : (
            <>
              <div className={classes.row}>
                <div className={classes.colImage}>
                  <div className={classes.ratio}>
                    {artist.fields.Images && artist.fields.Images.length > 0 && (
                      <img
                        className={classes.image}
                        src={
                          artist.fields.Images[1]?.thumbnails.full.url || artist.fields.Images[0].thumbnails.full.url
                        }
                        alt={artist.fields.Name}
                      />
                    )}
                  </div>
                  {!artist.fields['Partner Image'] || artist.fields['Partner Image'].length === 0 ? (
                    lang === 'en' ? (
                      <p className={classes.selectedby}>Selected by: {artist.fields['Selected by']}</p>
                    ) : (
                      <p className={classes.selectedby}>Selecionado por: {artist.fields['Selected by']}</p>
                    )
                  ) : (
                    <>
                      {lang === 'en' ? (
                        <p className={classes.selectedby}>Selected by:</p>
                      ) : (
                        <p className={classes.selectedby}>Selecionado por:</p>
                      )}
                      <img
                        className={classes.selectedbyImage}
                        src={
                          artist.fields['Partner Image'][1]?.thumbnails.large.url ||
                          artist.fields['Partner Image'][0].thumbnails.large.url
                        }
                        alt={artist.fields.Name}
                      />
                    </>
                  )}
                </div>

                <div className={classes.column}>
                  <h1 className={classes.title}>
                    {artist.fields.Name}
                  </h1>

                  <div className={classes.description}>

                    {lang === 'en' ? (
                      <p> {artist.fields.Bio && artist.fields.Bio}</p>
                    ) : (
                      <p> {artist.fields["BIO-PT"] && artist.fields["BIO-PT"]}</p>
                    )}

                  </div>

                  <div className={classes.embedPlayer} dangerouslySetInnerHTML={{ __html: artist.fields["Embed player"] }} />

                  <div className={classes.RowSocial}>
                    {artist.fields.Facebook && (
                      <a href={artist.fields.Facebook} target="_blank" rel="noopener noreferrer">
                        <img className={classes.socials} src={Facebook} alt="facebook" />
                      </a>
                    )}
                    {artist.fields.Youtube && (
                      <a href={artist.fields.Youtube} target="_blank" rel="noopener noreferrer">
                        <img className={classes.socials} src={Youtube} alt="Youtube" />
                      </a>
                    )}
                    {artist.fields.Instagram && (
                      <a href={artist.fields.Instagram} target="_blank" rel="noopener noreferrer">
                        <img className={classes.socials} src={Instagram} alt="Instagram" />
                      </a>
                    )}
                    {artist.fields.Website && (
                      <a href={artist.fields.Website} target="_blank" rel="noopener noreferrer">
                        <img className={classes.socials} src={Website} alt="Website" />
                      </a>
                    )}

                  </div>
                </div>
              </div>

              {isDesktop && (

                artist.fields.Video && (
                  <iframe
                    title={artist.fields.name}
                    className={classes.embed}
                    width="100%"
                    height="693px"
                    src={getEmbedUrl(artist.fields.Video)}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                )
              )}

              {isMobile && (
                <>
                  {artist.fields.Video ? (
                    <iframe
                      title={artist.fields.name}
                      className={classes.embed}
                      width="100%"
                      height="260px"
                      src={getEmbedUrl(artist.fields.Video)}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  ) : (
                    artist.fields.Embed && (
                      <div className={classes.embed} dangerouslySetInnerHTML={{ __html: artist.fields.Embed }} />
                    )
                  )}
                </>
              )}

            </>
          )}
        </>
      )}
    </div>
  );
};

export default ArtistDetail;
