import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: '120px 24px 120px 24px',
    width: "76%",
    maxWidth: '1280px',
    margin: '0 auto',
  },
  artistsList: {
    width: "100%",
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: '2rem',
  },
  artists: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '33.33%',
    padding: '3rem',
    color: 'var(--red)',
    '& img': {
      height: '250px',
      objectFit: 'contain',
    },
    '& img.big': {
      height: '4.2cm',
      padding: 0,
    },
  },
  artistsTitle: {
    fontFamily: 'ABCDiatypeRoundedUnlicensedTrial',
    fontSize: 40,
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: 'var(--red)',
    paddingBottom: '2rem',
  },
  artistTitle: {
    fontFamily: 'Switzer',
    fontSize: 32,
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    padding: '1rem 0',
    '& span': {
      fontSize: '16px',
    },
  },

  artistAbout: {
    fontFamily: 'Switzer',
    fontSize: 12,
    fontWeight: 600,
    fontStyle: 'normal',
    letterSpacing: 0.4,
    textAlign: 'center',
    '& span': {
      fontSize: '16px',
    },
  },

  detailButton: {
    marginTop: '10px',
    padding: '10px 20px',
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0056b3',
    },
  },
  '@media (max-width: 1024px)': {
    container: {
      width: "100%",
      padding: '120px 1.5rem 80px 1.5rem',
    },
    artists: {
      width: '50%',
    },
    artistsSection: {
      padding: '4rem 1rem',
    },
  },
  '@media (max-width: 560px)': {
    artists: {
      width: '100%',
    },
  },
});

export default useStyles;
