import React, { useEffect } from 'react';
import useStyles from './styles';
import { useLocation } from 'react-router-dom';

import Header from 'components/common/header';
import ArtistDetail from 'components/artist-detail';
import Footer from 'components/common/footer';

const ArtistDetailPage = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const classes = useStyles();
  const lang = pathname.startsWith('/pt') ? 'pt' : 'en';

  return (
    <>
      <Header artists lang={lang} />
      <main className={classes.main}>
        <ArtistDetail lang={lang} />
      </main>
      <Footer lang={lang} />
    </>
  );
};

export default ArtistDetailPage;
